
export default {
    name: 'Report',
    props: {
        /**
         * Boolean to disable NuxtLink
         */
        disableLink: {
            type: Boolean,
            default: false,
        },
        /**
         * A report object
         */
        report: {
            type: Object,
            required: true,
        },
        /**
         * Override the nuxt link target
         */
        to: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    computed: {
        displayAddress() {
            let address = '';
            if (this.report.road) {
                address += `${this.report.road.name}, `;
            }
            if (this.report.city) {
                address += this.report.city.name;
            }

            return address;
        },
    },
};
