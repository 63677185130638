
import externalLink from '~/mixins/externalLink';

/* eslint vue/no-v-html: 0 */
export default {
    name: 'SiteList',
    mixins: [externalLink],
    props: {
        /**
         * Array of items to show
         * { icon: '', text: '' }
         */
        items: {
            type: Array,
            required: true,
        },
        /**
         * Applies flex & wrap, displays items horizontal
         */
        horizontal: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (window.cordova) {
            this.$el.querySelectorAll('a[href^="https"]').forEach((link) => {
                link.addEventListener('click', this.externalLink);
            });
        }
    },
};

