
export default {
    name: 'ImageCover',
    props: {
        /**
         * An Image object
         */
        image: {
            type: Object,
            required: true,
        },
        /**
         * Height in px for the image
         */
        size: {
            type: Number,
            default: 150,
        },
    },
    computed: {
        fallbackImage() {
            return this.image.small ?? this.image.original;
        },
        srcset() {
            if (this.size >= 300) {
                return this.image.medium;
            }
            if (this.size >= 100) {
                return this.image['thumbnail@2x'] || this.image.thumbnail;
            }
            return this.image.thumbnail;
        },
    },
};
