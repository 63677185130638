
import { mapState } from 'vuex';

export default {
    name: 'HomePageSection',
    props: {
        /**
         * Array index
         */
        index: {
            type: Number,
            required: true,
        },
        /**
         * An array of fly-tipping reports
         */
        reports: {
            type: Array,
            default() {
                return [];
            },
        },
        /**
         * { title, text, image, icon, sections }
         */
        section: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(['isMobile']),
        imageIsSvg() {
            return this.section.image?.indexOf('.svg') !== -1;
        },
        returnBackgroundImage() {
            return this.isMobile ? this.section.coverMobile : this.section.cover;
        },
    },
};
