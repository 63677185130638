
export default {
    data() {
        return {
            hasSlot: this.$slots.default && this.$slots.default.length,
            path: 'M256 8c137 0 248 111 248 248s-111 248-248 248-248-111-248-248 111-248 248-248z',
        };
    },
};

