
const embedUrlBase = 'https://www.youtube.com/embed';
const thumbUrlBase = 'https://i.ytimg.com/vi';
export default {
    name: 'YoutubeEmbed',
    props: {
        vid: { type: String, required: true },
        params: { type: Object, default: () => {} },
    },
    data() {
        return {
            isClicked: false,
        };
    },
    computed: {
        contentStyle() {
            return {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
            };
        },
        wrapperStyle() {
            return {
                width: '100%',
                height: '0px',
                position: 'relative',
                'padding-bottom': `${(9 / 16) * 100}%`,
            };
        },
        src() {
            const autoplayParams = { ...this.params, autoplay: 1 };
            const query = Object.keys(autoplayParams)
                .reduce((prev, curr) => [...prev, [curr, autoplayParams[curr]].join('=')], [])
                .join('&');
            return [[embedUrlBase, this.vid].join('/'), query].join('?');
        },
        thumbUrl() {
            return [thumbUrlBase, this.vid, 'hqdefault.jpg'].join('/');
        },
        thumbStyle() {
            return {
                ...this.contentStyle,
                background: 'rgba(0, 0, 0, 0.6) no-repeat center center',
                'background-size': 'cover',
                cursor: 'pointer',
                overflow: 'hidden',
                display: 'inline-block',
                'background-image': `url(${this.thumbUrl})`,
            };
        },
    },
};
