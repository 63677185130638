import { render, staticRenderFns } from "./HomePageSection.vue?vue&type=template&id=52ae964c&scoped=true"
import script from "./HomePageSection.vue?vue&type=script&lang=js"
export * from "./HomePageSection.vue?vue&type=script&lang=js"
import style0 from "./HomePageSection.vue?vue&type=style&index=0&id=52ae964c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ae964c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaYouTubeEmbed: require('/opt/atlassian/pipelines/agent/build/components/media/YouTubeEmbed.vue').default,IconCircle: require('/opt/atlassian/pipelines/agent/build/components/icon/IconCircle.vue').default,SiteList: require('/opt/atlassian/pipelines/agent/build/components/SiteList.vue').default,FormSiteButton: require('/opt/atlassian/pipelines/agent/build/components/form/SiteButton.vue').default,FlytippingReport: require('/opt/atlassian/pipelines/agent/build/components/flytipping/Report.vue').default,GridWrapper: require('/opt/atlassian/pipelines/agent/build/components/GridWrapper.vue').default})
